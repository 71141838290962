import React from 'react';
import styled from '@emotion/styled';

import Row from '../../templates/Row';
import Column from '../../templates/Column';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0.04);
`;

const ReverseMobileRow = styled(Row)`
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const Info = styled.div`
    text-align: center;
    padding: 20px 10px;
    color: #00838f;
    display: block;
    max-width: 400px;
`;

const InfoLeft = styled(Info)`
    margin-left: auto;
    
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const InfoRight = styled(Info)`
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const Title = styled.h2`
    
`;

const Description = styled.div`
    display: block;
    text-align: justify;
    margin: auto;
`;

const Image = styled.img`
    width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 131, 143, 0.2), 0 6px 20px 0 rgba(0, 131, 143, 0.19);
`;

interface ObjectsProps {

}

const Objects: any = (props: ObjectsProps) => {
    return (
        <Content>
            <Row>
                <Column nbCol={2}>
                    <InfoLeft>
                        <Image alt="fonction gestion des objets" src="/images/feature-manage-object.jpg"/>
                    </InfoLeft>
                </Column>
                <Column nbCol={2}>
                    <InfoRight>
                        <Title>
                            Gestion des objets
                        </Title>
                        <Description>
                            Dans chaque collection vous aurez la possibilité d'ajouter, de modifier et de supprimer des objets. Remplissez les informations comme vous le souhaitez : avec un nom, une description, des tags et des champs personnalisés. Le but étant d'effectuer une gestion à votre image.
                        </Description>
                    </InfoRight>
                </Column>
            </Row>
        </Content>
    );
}

export default Objects;