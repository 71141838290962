import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import Menu from '../components/menu/Menu';
import Footer from '../components/footer/Footer';
import HeightSpace from '../components/templates/HeightSpace';
import Collection from '../components/contents/features/Collection';
import Objects from '../components/contents/features/Objects';
import Custom from '../components/contents/features/Custom';
import Template from '../components/contents/features/Template';
import Search from '../components/contents/features/Search';

const TitleArea = styled.div`
    display: block;
    padding: 20px 10px;
    color: #00838f;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5em;

    @media (max-width: 1200px) {
        font-size: 2em;
    }
`;

const Subtitle = styled.h2`
    font-weight: normal;
    font-size: 1.5em;
`;

export const query = graphql`
    query {
        allItemsJson {
            __typename
            edges {
                __typename
                node {
                    __typename
                    text
                    linkTo
                }
            }
        }
    }
`;

const Features: any = ({data}: any) => {
    return (
        <>
            <Helmet>
                <title>Collectoogo - Fontionnalités</title>
                <meta name="description" content="Collectoogo vous propose plusieurs fonctionnalités, très utiles, pour mener à bien votre gestion de collection d'objets." />
                <meta property="og:url" content="https://collectoogo.fr" />
                <meta property="og:type" content="siteweb" />
                <meta property="og:title" content="Collectoogo - Fontionnalités" />
                <meta property="og:description" content="Collectoogo vous propose plusieurs fonctionnalités, très utiles, pour mener à bien votre gestion de collection d'objets." />
                <meta property="og:image" content="https://collectoogo.fr/images/og-collectoogo.jpg" />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://collectoogo.fr",
                            "logo": "https://collectoogo.fr/images/logo-collectoogo-white.svg"
                        }
                    `}
                </script>
            </Helmet>
            <Menu items={data.allItemsJson.edges}>
                <HeightSpace/>
                <TitleArea>
                    <Title>
                        Fontionnalités
                    </Title>
                    <Subtitle>
                        Les fonctionnalités qui vous sont proposées pour gérer au mieux vos différentes collections.<br/><br/>
                    </Subtitle>
                </TitleArea>
                <Collection/>
                <Objects/>
                <Custom/>
                <Template/>
                <Search/>
                <Footer/>
            </Menu>
        </>
    );
}

export default Features;