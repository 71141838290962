import React from 'react';
import styled from '@emotion/styled';

import Row from '../../templates/Row';
import Column from '../../templates/Column';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
`;

const ReverseMobileRow = styled(Row)`
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const Info = styled.div`
    text-align: center;
    padding: 20px 10px;
    color: #00838f;
    display: block;
    max-width: 400px;
`;

const InfoLeft = styled(Info)`
    margin-left: auto;
    
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const InfoRight = styled(Info)`
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const Title = styled.h2`
    
`;

const Description = styled.div`
    display: block;
    text-align: justify;
    margin: auto;
`;

const Image = styled.img`
    width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 131, 143, 0.2), 0 6px 20px 0 rgba(0, 131, 143, 0.19);
`;

interface SearchProps {

}

const Search: any = (props: SearchProps) => {
    return (
        <Content>
            <ReverseMobileRow>
                <Column nbCol={2}>
                    <InfoLeft>
                        <Title>
                            Recherche globale
                        </Title>
                        <Description>
                            Afin de retrouver facilement un objet ou une collection en particulier, vous pourrez effectuer une recherche parmi tous les champs de saisie qui composent l'ensemble de vos objets et collections. De ce fait, si vous souhaitez vérifier la présence d'un objet dans votre collection, vous pourrez le faire rapidement.
                        </Description>
                    </InfoLeft>
                </Column>
                <Column nbCol={2}>
                    <InfoRight>
                        <Image alt="fonction de recherche" src="/images/feature-search.jpg"/>
                    </InfoRight>
                </Column>
            </ReverseMobileRow>
        </Content>
    );
}

export default Search;