import React from 'react';
import styled from '@emotion/styled';

import Row from '../../templates/Row';
import Column from '../../templates/Column';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
`;

const ReverseMobileRow = styled(Row)`
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const Info = styled.div`
    text-align: center;
    padding: 20px 10px;
    color: #00838f;
    display: block;
    max-width: 400px;
`;

const InfoLeft = styled(Info)`
    margin-left: auto;
    
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const InfoRight = styled(Info)`
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const Title = styled.h2`
    
`;

const Description = styled.div`
    display: block;
    text-align: justify;
    margin: auto;
`;

const Image = styled.img`
    width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 131, 143, 0.2), 0 6px 20px 0 rgba(0, 131, 143, 0.19);
`;

interface CustomProps {

}

const Custom: any = (props: CustomProps) => {
    return (
        <Content>
            <ReverseMobileRow>
                <Column nbCol={2}>
                    <InfoLeft>
                        <Title>
                            Personnalisation des champs
                        </Title>
                        <Description>
                            Pour chacun des objets, il vous sera possible d'ajouter des champs personnalisés tels que : des champs texte, zone de texte, nombre, date ou bien un champs de notation sur 5. D'autres pourront apparaître à l'avenir.
                        </Description>
                    </InfoLeft>
                </Column>
                <Column nbCol={2}>
                    <InfoRight>
                        <Image alt="fonction de personnalisation" src="/images/feature-custom.jpg"/>
                    </InfoRight>
                </Column>
            </ReverseMobileRow>
        </Content>
    );
}

export default Custom;