import React from 'react';
import styled from '@emotion/styled';

const Content = styled.div`
    width: 100%;
    height: 77px;
    position: relative;
`;

interface HeightSpaceProps {

}

const HeightSpace: any = (props: HeightSpaceProps) => {
    return (
        <Content/>
    );
}

export default HeightSpace;